import React from 'react';
import { Link, Layout } from 'components';
import './thank-you.scss';

const ThankYou = (props) => {
  const { location } = props;
   const query = location.search.substring(1);
   const splitQuery = query.split("=");
   const queryLink = splitQuery[1]

  return (
    <Layout>
      <div className="thank-you-container">
        <h1>Thanks for your submission</h1>
        {queryLink && <Link className="link lg rounded secondary invert download-link" to={queryLink}>Download Ebook</Link>}
        {!queryLink && <Link className="link lg rounded secondary invert download-link" to="/">Back to home</Link>}
      </div>
    </Layout>
  )
};

export default ThankYou;
